import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/auth/auth/auth.module').then(m => m.AuthPageModule) },
  { path: '', loadChildren: () => import('./pages/nav/nav.module').then(m => m.NavPageModule) },
  { path: '', loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule) },
  { path: 'logout', loadChildren: () => import('./pages/auth/logout/logout.module').then(m => m.LogoutPageModule) },
  { path: '**', loadChildren: () => import('./pages/common/not-found/not-found.module').then(m => m.NotFoundPageModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
