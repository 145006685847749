import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private envTitle = environment.environment_name;
  private appTitle = 'InstallMySigns';

  constructor(
    private titleService: Title,
  ) {
    
  }
  public setTitle(newTitle: string) {
    if(environment.production == false){
      this.envTitle = environment.environment_name;
      this.titleService.setTitle(this.envTitle + ' | ' + newTitle + ' | ' + this.appTitle);
    } else {
      this.titleService.setTitle(newTitle + ' | ' + this.appTitle);
    }
  }
}
