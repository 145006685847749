import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { AuthService } from '@app/services/auth.service';
import {
  BrokerSearch,
  CompanySearch,
  CityRestrictionSearch,
  CitySearch,
  InstallerSearch,
  OrderSearch,
  SortableSearch,
  UserSearch,
  RoleSearch,
  MapSearch,
  ProspectCompanySearch,
  Pagination,
} from '@app/interfaces/search';
import {
  AssetType, AssetTypeCreate,
  City, CityCreate,
  CityRestriction, CityRestrictionCreate,
  Office, OfficeCreate,
  Order, OrderCreate,
  Document, DocumentCreate,
  SignStyle,
  Sortable,
  LoginResult,
  Role,
  ProspectCompany,
  FeatureAssetTypeCreate,
  FeatureAssetType,
  CityCodeFulfillmentCreate,
  CityCodeFulfillment,
  SignRequestCreate
} from '@app/interfaces/models';
import { TableColumnSort } from '@app/interfaces/table-column';
import { SubscriptionTrialCheck } from '@app/interfaces/subscriptions';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public url = environment.api_url;
  private accessToken = null;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {

  }

  public getUserPhoto(id: number) {
    return this.apiTokenGet('api/photo/users/'+id);
  }

  // routes
  public approveAsset(id: number, postData: any) {
    return this.apiTokenPatch('api/assets/'+id+'/approve', postData);
  }
  public rejectAsset(id: number, postData: any) {
    return this.apiTokenPatch('api/assets/'+id+'/reject', postData);
  }
  public removeAsset(id: number, postData: any) {
    return this.apiTokenPatch('api/assets/'+id, postData);
  }
  public getAssetTypes() {
    return this.apiTokenGet('api/asset-types');
  }
  public getAssetType(id: number) {
    return this.apiTokenGet('api/asset-types/'+id);
  }
  public createAssetType(postData: AssetTypeCreate) {
    return this.apiTokenPost('api/asset-types', postData);
  }
  public updateAssetType(id: number, postData: AssetType) {
    return this.apiTokenPatch('api/asset-types/'+id, postData);
  }
  public updateAssetTypeSort(postData: Sortable) {
    return this.apiTokenPost('api/asset-types/sort', postData);
  }
  public deleteAssetType(id: number) {
    return this.apiTokenDelete('api/asset-types/'+id);
  }

  public getProspectMapCompanies(search: MapSearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/prospects?'+query);
  }
  public getProspectCompanies(search: ProspectCompanySearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/prospect-companies?'+query);
  }
  public getProspectCompany(id: number) {
    return this.apiTokenGet('api/prospect-companies/'+id);
  }
  public createProspectCompany(postData: any) {
    return this.apiTokenPost('api/prospect-companies', postData);
  }
  public updateProspectCompany(id: number, postData: any) {
    return this.apiTokenPatch('api/prospect-companies/'+id, postData);
  }
  public deleteProspectCompany(id: number) {
    return this.apiTokenDelete('api/prospect-companies/'+id);
  }
  public searchProspectCompany(query: string) {
    return this.apiTokenPost<ProspectCompany[]>('api/prospect-companies/search', { search: query });
  }
  public createProspectNote(postData: any) {
    return this.apiTokenPost('api/prospect-notes', postData);
  }
  public createProspectContact(postData: any) {
    return this.apiTokenPost('api/prospect-contacts', postData);
  }

  public createApprovedConnection(postData: any){
    return this.apiTokenPost('api/approved-connections', postData);
  }

  public getCompanies(search: CompanySearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/companies?' + query);
  }
  public getCompanySubscriptions(ids: number[]){
    return this.apiTokenGet('api/companies/subscriptionsCount', {ids: ids.join(',')});
  }
  public getCompany(id: number) {
    return this.apiTokenGet('api/companies/'+id);
  }
  public getPublicCompany(id: number) {
    return this.apiTokenGet('api/companies/'+id+'/public');
  }

  public getNearbyCities(id: number, search: Pagination) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/companies/'+id+'/nearbyCities?' + query);
  }
  public getNearbyProspectCompanies(id: number, search: Pagination) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/companies/'+id+'/nearbyProspectCompanies?' + query);
  }
  public getCompaniesNearby(id: number) {
    return this.apiTokenGet('api/companies/'+id+'/nearby');
  }
  public createTestOrder(id: number) {
    return this.apiTokenGet('api/companies/'+id+'/createTestOrder');
  }
  public getMyCompany() {
    return this.apiTokenGet('api/companies/mine');
  }
  public getCompanyCityCodeFulfillments(id: number) {
    return this.apiTokenGet('api/companies/'+id+'/companyCityCodeFulfillments');
  }
  public getMyClientCompanies(order_id: number = null) {
    if(order_id !== null){
      return this.apiTokenGet('api/companies/myClients?order_id='+order_id);
    }
    return this.apiTokenGet('api/companies/myClients');
  }
  public getMyFieldUsers(order_id: number = null) {
    if(order_id !== null){
      return this.apiTokenGet('api/users/myFieldUsers?order_id='+order_id);
    }
    return this.apiTokenGet('api/users/myFieldUsers');
  }
  public sendWelcomeEmailUser(id: number) {
    return this.apiTokenGet('api/users/'+id+'/welcome');
  }
  public createCompany(postData: FormData) {
    return this.apiTokenPost('api/companies', postData);
  }
  public uploadNda(id: number, postData: FormData) {
    return this.apiTokenPost('api/companies/'+id+'/uploadNda', postData);
  }
  public updateCompany(id: number, postData: FormData) {
    return this.apiTokenPost('api/companies/'+id, postData);
  }
  public companyCancel(id: number) {
    return this.apiTokenPatch('api/companies/'+id+'/cancel', {});
  }
  public companyCompleteOnboarding(id: number, postData: any) {
    return this.apiTokenPatch('api/companies/'+id+'/completeOnboarding', postData);
  }
  public deleteCompany(id: number) {
    return this.apiTokenDelete('api/companies/'+id);
  }
  public getInstallersNearby(search: InstallerSearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/companies/installersearch?'+query);
  }
  public getInstallerCompanies() {
    return this.apiTokenGet('api/companies/installers');
  }

  public getOffices(company_id: number = null, using_exclusive_companies: boolean = false, include_deleted: boolean = false) {
    if(company_id === null){
      return this.apiTokenGet('api/offices');
    } else {
      let queryString:string = '';
      if(using_exclusive_companies){
        queryString = '&using_exclusive_companies=true';
      }
      if(include_deleted){
        queryString = queryString + '&include_deleted=true';
      }
      return this.apiTokenGet('api/offices?company_id='+company_id+queryString);
    }
  }
  public getOffice(id: number) {
    return this.apiTokenGet('api/offices/'+id);
  }
  public createOffice(postData: OfficeCreate) {
    return this.apiTokenPost('api/offices', postData);
  }
  public updateOffice(id: number, postData: OfficeCreate) {
    return this.apiTokenPatch('api/offices/'+id, postData);
  }
  public restoreOffice(id: number) {
    return this.apiTokenPost('api/offices/'+id+'/restore', {});
  }
  public deleteOffice(id: number) {
    return this.apiTokenDelete('api/offices/'+id);
  }
  public getManageableOfficesByCompany(companyId: number) {
    return this.apiTokenGet('api/offices/currentUserCanManageByCompany/'+companyId);
  }

  public getUsers(search: UserSearch = null) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/users?'+query);
  }
  public getUsersByOffice(id: number, sort?: SortableSearch) {
    let url = 'api/users/office/'+id;
    if(sort) {
      url += '?sort_property='+sort.sort_property+'&sort_direction='+sort.sort_direction;
    }
    return this.apiTokenGet(url);
  }
  public getUser(id: number) {
    return this.apiTokenGet('api/users/'+id);
  }
  public createUser(postData: FormData) {
    return this.apiTokenPost('api/users', postData);
  }
  public updateUser(id: number, postData: FormData) {
    return this.apiTokenPost('api/users/'+id, postData);
  }
  public deleteUser(id: number) {
    return this.apiTokenDelete('api/users/'+id);
  }
  public restoreUser(id: number) {
    return this.apiTokenPost('api/users/'+id+'/restore', {});
  }
  public registerUser(userAndCompany: any) {
    return this.apiPublicPost('api/users/register', userAndCompany);
  }
  public impersonateDemoUserId(){
    return this.apiTokenGet<number>('api/admin/impersonateDemoUserId/');
  }
  public impersonateUser(id: number) {
    return this.apiTokenGet<LoginResult>('api/users/impersonate/'+id);
  }
  public impersonateDemoUser() {
    return this.apiTokenGet<LoginResult>('api/users/impersonate-demo');
  }

  public getCitiesSearch(search) {
    return this.apiTokenGet('api/cities?city='+search);
  }
  public getCitiesSimple(search) {
    return this.apiTokenGet('api/cities/search?city='+search);
  }
  public getCity(id: number) {
    return this.apiTokenGet('api/cities/'+id);
  }
  public createCity(postData: CityCreate) {
    return this.apiTokenPost('api/cities', postData);
  }
  public updateCity(id: number, postData: City) {
    return this.apiTokenPatch('api/cities/'+id, postData);
  }
  public deleteCity(id: number) {
    return this.apiTokenDelete('api/cities/'+id);
  }

  public getInternalNote(id: number) {
    return this.apiTokenGet('api/internal-notes/'+id);
  }
  public createInternalNote(postData: any) {
    return this.apiTokenPost('api/internal-notes', postData);
  }
  //public updateInternalNote(id: number, postData: any) {
  //  return this.apiTokenPatch('api/internal-notes/'+id, postData);
  //}
  //public deleteInternalNote(id: number) {
  //  return this.apiTokenDelete('api/internal-notes/'+id);
  //}

  public getCityRestrictions(search: CityRestrictionSearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/city-restrictions?'+query);
  }
  public getCityRestriction(id: number) {
    return this.apiTokenGet('api/city-restrictions/'+id);
  }
  public createCityRestriction(postData: CityRestrictionCreate) {
    return this.apiTokenPost('api/city-restrictions', postData);
  }
  public updateCityRestriction(id: number, postData: CityRestriction) {
    return this.apiTokenPatch('api/city-restrictions/'+id, postData);
  }
  public deleteCityRestriction(id: number) {
    return this.apiTokenDelete('api/city-restrictions/'+id);
  }

  public createCityCodeFulfillmentsPublic(postData: CityCodeFulfillmentCreate) {
    return this.apiTokenPost('api/city-code-fulfillments/customerOrder', postData);
  }
  public getCityCodeFulfillments(){
    return this.apiTokenGet('api/city-code-fulfillments');
  }
  public getCityCodeFulfillment(id:number){
    return this.apiTokenGet('api/city-code-fulfillments/'+id);
  }
  public updateCityCodeFulfillment(id: number, postData: CityCodeFulfillment) {
    return this.apiTokenPatch('api/city-code-fulfillments/'+id, postData);
  }
  public createCityCodeForFulfillment(id: number, postData: CityRestrictionCreate) {
    return this.apiTokenPost('api/city-code-fulfillments/'+id+'/createCityCode', postData);
  }

  public uploadDocument(id: number, postData: any) {
    return this.apiTokenPost('api/city-restrictions/'+id+'/upload', postData);
  }
  public getDocument(id: number) {
    return this.apiTokenGet('api/documents/'+id);
  }
  public updateDocument(id: number, postData: any) {
    return this.apiTokenPost('api/documents/'+id, postData);
  }
  public deleteDocument(id: number) {
    return this.apiTokenDelete('api/documents/'+id);
  }

  public getCompanyTypes() {
    return this.apiTokenGet('api/company-types');
  }
  public getCompanyType(id: number) {
    return this.apiTokenGet('api/company-types/'+id);
  }
  public createCompanyType(postData: any) {
    return this.apiTokenPost('api/company-types', postData);
  }
  public updateCompanyType(id: number, postData: any) {
    return this.apiTokenPatch('api/company-types/'+id, postData);
  }
  public deleteCompanyType(id: number) {
    return this.apiTokenDelete('api/company-types/'+id);
  }

  public getInviteContents() {
    return this.apiTokenGet('api/contents/invites/all');
  }
  public getContents() {
    return this.apiTokenGet('api/contents');
  }
  public getContent(id: number) {
    return this.apiTokenGet('api/contents/'+id);
  }
  public getContentByKey(key: string) {
    return this.apiTokenGet('api/contents/key/'+key);
  }
  public createContent(postData: any) {
    return this.apiTokenPost('api/contents', postData);
  }
  public updateContent(id: number, postData: any) {
    return this.apiTokenPatch('api/contents/'+id, postData);
  }
  public deleteContent(id: number) {
    return this.apiTokenDelete('api/contents/'+id);
  }
  public uploadImage(postData: any) {
    return this.apiTokenPost('api/contents/upload', postData);
  }

  public getPublicFeature(id: number) {
    return this.apiTokenGet('api/features/'+id);
  }
  public getFeatures() {
    return this.apiTokenGet('api/features');
  }
  public getFeature(id: number) {
    return this.apiTokenGet('api/features/'+id);
  }
  public createFeature(postData: any) {
    return this.apiTokenPost('api/features', postData);
  }
  public updateFeature(id: number, postData: any) {
    return this.apiTokenPatch('api/features/'+id, postData);
  }
  public deleteFeature(id: number) {
    return this.apiTokenDelete('api/features/'+id);
  }
  public createCompanyFeature(postData: any) {
    return this.apiTokenPost('api/company-feature', postData);
  }
  public deleteCompanyFeature(company_id: number, feature_id: number) {
    return this.apiTokenDelete('api/company-feature?company_id='+company_id+'&feature_id='+feature_id);
  }
  public getFeatureAssets() {
    return this.apiTokenGet('api/feature-assets');
  }
  public getFeatureAsset(id: number) {
    return this.apiTokenGet('api/feature-assets/'+id);
  }
  public createFeatureAsset(id: number, postData: any) {
    return this.apiTokenPost('api/features/'+id+'/upload', postData);
  }
  public updateFeatureAsset(id: number, postData: any) {
    return this.apiTokenPost('api/feature-assets/'+id, postData);
  }
  public updateFeatureAssetSort(postData: Sortable) {
    return this.apiTokenPost('api/feature-assets/sort', postData);
  }
  public deleteFeatureAsset(id: number) {
    return this.apiTokenDelete('api/feature-assets/'+id);
  }
  public getFeatureAssetTypes() {
    return this.apiTokenGet('api/feature-asset-types');
  }
  public getFeatureAssetType(id: number) {
    return this.apiTokenGet('api/feature-asset-types/'+id);
  }
  public createFeatureAssetType(postData: FeatureAssetTypeCreate) {
    return this.apiTokenPost('api/feature-asset-types', postData);
  }
  public updateFeatureAssetType(id: number, postData: FeatureAssetType) {
    return this.apiTokenPatch('api/feature-asset-types/'+id, postData);
  }

  public getSignTypes() {
    return this.apiTokenGet('api/sign-types');
  }
  public getSignType(id: number) {
    return this.apiTokenGet('api/sign-types/'+id);
  }
  public createSignType(postData: any) {
    return this.apiTokenPost('api/sign-types', postData);
  }
  public updateSignType(id: number, postData: any) {
    return this.apiTokenPatch('api/sign-types/'+id, postData);
  }
  public deleteSignType(id: number) {
    return this.apiTokenDelete('api/sign-types/'+id);
  }

  public getProofStatuses() {
    return this.apiTokenGet('api/proof-status');
  }
  public getProofStatus(id: number) {
    return this.apiTokenGet('api/proof-status/'+id);
  }
  public createProofStatus(postData: any) {
    return this.apiTokenPost('api/proof-status', postData);
  }
  public updateProofStatus(id: number, postData: any) {
    return this.apiTokenPatch('api/proof-status/'+id, postData);
  }
  public updateProofStatusSort(postData: Sortable) {
    return this.apiTokenPost('api/proof-status/sort', postData);
  }
  public deleteProofStatus(id: number) {
    return this.apiTokenDelete('api/proof-status/'+id);
  }

  public getOrderStatuses() {
    return this.apiTokenGet('api/order-status');
  }
  public getOrderStatus(id: number) {
    return this.apiTokenGet('api/order-status/'+id);
  }
  public createOrderStatus(postData: any) {
    return this.apiTokenPost('api/order-status', postData);
  }
  public updateOrderStatus(id: number, postData: any) {
    return this.apiTokenPatch('api/order-status/'+id, postData);
  }
  public updateOrderStatusSort(postData: Sortable) {
    return this.apiTokenPost('api/order-status/sort', postData);
  }
  public deleteOrderStatus(id: number) {
    return this.apiTokenDelete('api/order-status/'+id);
  }

  public getSignRequests(status: string = null) {
    if(status === null){
      return this.apiTokenGet('api/sign-requests');
    } else {
      return this.apiTokenGet('api/sign-requests?status='+status);
    }
  }
  public getSignRequest(id: number) {
    return this.apiTokenGet('api/sign-requests/'+id);
  }
  public createSignRequest(postData: SignRequestCreate) {
    return this.apiTokenPost('api/sign-requests', postData);
  }
  public updateSignRequest(id: number, postData: any) {
    return this.apiTokenPatch('api/sign-requests/'+id, postData);
  }

  public getSignRequestStatuses() {
    return this.apiTokenGet('api/sign-request-status');
  }
  public getSignRequestStatus(id: number) {
    return this.apiTokenGet('api/sign-request-status/'+id);
  }
  public createSignRequestStatus(postData: any) {
    return this.apiTokenPost('api/sign-request-status', postData);
  }
  public updateSignRequestStatus(id: number, postData: any) {
    return this.apiTokenPatch('api/sign-request-status/'+id, postData);
  }
  public updateSignRequestStatusSort(postData: Sortable) {
    return this.apiTokenPost('api/sign-request-status/sort', postData);
  }
  public deleteSignRequestStatus(id: number) {
    return this.apiTokenDelete('api/sign-request-status/'+id);
  }

  public getServiceAreaByCompany(company_id: number) {
    return this.apiTokenGet('api/service-areas?company_id='+company_id);
  }
  public createOrUpdateServiceArea(postData: any) {
    return this.apiTokenPost('api/service-areas', postData);
  }

  public getZonesByCompany(company_id: number) {
    return this.apiTokenGet('api/zones?company_id='+company_id);
  }

  public getServiceTypes() {
    return this.apiTokenGet('api/service-types');
  }
  public getServiceType(id: number) {
    return this.apiTokenGet('api/service-types/'+id);
  }
  public createServiceType(postData: any) {
    return this.apiTokenPost('api/service-types', postData);
  }
  public updateServiceType(id: number, postData: any) {
    return this.apiTokenPatch('api/service-types/'+id, postData);
  }
  public updateServiceTypeSort(postData: Sortable) {
    return this.apiTokenPost('api/service-types/sort', postData);
  }
  public deleteServiceType(id: number) {
    return this.apiTokenDelete('api/service-types/'+id);
  }

  public getSignSizes() {
    return this.apiTokenGet('api/sign-sizes');
  }
  public getSignSize(id: number) {
    return this.apiTokenGet('api/sign-sizes/'+id);
  }
  public createSignSize(postData: any) {
    return this.apiTokenPost('api/sign-sizes', postData);
  }
  public updateSignSize(id: number, postData: any) {
    return this.apiTokenPatch('api/sign-sizes/'+id, postData);
  }
  public updateSignSizeSort(postData: Sortable) {
    return this.apiTokenPost('api/sign-sizes/sort', postData);
  }
  public deleteSignSize(id: number) {
    return this.apiTokenDelete('api/sign-sizes/'+id);
  }

  public getSignStyles(): Promise<SignStyle[]|any> {
    return this.apiTokenGet('api/sign-styles');
  }
  public getSignStyle(id: number) {
    return this.apiTokenGet('api/sign-styles/'+id);
  }
  public createSignStyle(postData: any) {
    return this.apiTokenPost('api/sign-styles', postData);
  }
  public updateSignStyle(id: number, postData: any) {
    return this.apiTokenPatch('api/sign-styles/'+id, postData);
  }
  public updateSignStyleSort(postData: Sortable) {
    return this.apiTokenPost('api/sign-styles/sort', postData);
  }
  public deleteSignStyle(id: number) {
    return this.apiTokenDelete('api/sign-styles/'+id);
  }

  public getSignTextTypes() {
    return this.apiTokenGet('api/sign-text-types');
  }
  public getSignTextType(id: number) {
    return this.apiTokenGet('api/sign-text-types/'+id);
  }
  public createSignTextType(postData: any) {
    return this.apiTokenPost('api/sign-text-types', postData);
  }
  public updateSignTextType(id: number, postData: any) {
    return this.apiTokenPatch('api/sign-text-types/'+id, postData);
  }
  public updateSignTextTypeSort(postData: Sortable) {
    return this.apiTokenPost('api/sign-text-types/sort', postData);
  }
  public deleteSignTextType(id: number) {
    return this.apiTokenDelete('api/sign-text-types/'+id);
  }

  public getNotificationTypes() {
    return this.apiTokenGet('api/notification-types');
  }
  public getNotificationType(id: number) {
    return this.apiTokenGet('api/notification-types/'+id);
  }
  public createNotificationType(postData: any) {
    return this.apiTokenPost('api/notification-types', postData);
  }
  public updateNotificationType(id: number, postData: any) {
    return this.apiTokenPatch('api/notification-types/'+id, postData);
  }
  public deleteNotificationType(id: number) {
    return this.apiTokenDelete('api/notification-types/'+id);
  }

  public getUserTypes() {
    return this.apiTokenGet('api/user-types');
  }
  public getUserType(id: number) {
    return this.apiTokenGet('api/user-types/'+id);
  }
  public createUserType(postData: any) {
    return this.apiTokenPost('api/user-types', postData);
  }
  public updateUserType(id: number, postData: any) {
    return this.apiTokenPatch('api/user-types/'+id, postData);
  }
  public deleteUserType(id: number) {
    return this.apiTokenDelete('api/user-types/'+id);
  }

  public getDashboard(search: any) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/dashboard?'+query);
  }

  public getOrders(search: OrderSearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/orders?'+query);
  }
  public getOrder(id: number) {
    return this.apiTokenGet('api/orders/'+id);
  }
  public getOrderAssets(postData: any) {
    return this.apiTokenPost('api/orders/assets', postData);
  }
  public getOrderTotals(postData: any) {
    return this.apiTokenPost('api/orders/totals', postData);
  }
  public createOrder(postData: OrderCreate) {
    return this.apiTokenPost('api/orders', postData);
  }
  public updateOrder(id: number, postData: any) {
    return this.apiTokenPatch('api/orders/'+id, postData);
  }
  public updateFieldUsersOrder(id: number, postData: any) {
    return this.apiTokenPatch('api/orders/'+id+'/field-users', postData);
  }
  public scheduleOrder(id: number, postData: any) {
    return this.apiTokenPatch('api/orders/'+id+'/schedule', postData);
  }
  public uploadOrderFile(id: number, postData: any) {
    return this.apiTokenPost('api/orders/'+id+'/upload', postData);
  }
  public completeOrder(id: number, postData: any) {
    return this.apiTokenPost('api/orders/'+id+'/complete', postData);
  }
  public updateOrderCurrentStatus(id: number, postData: any) {
    return this.apiTokenPatch('api/orders/'+id+'/status', postData);
  }
  public updateOrderLocation(id: number, postData: {latitude: number, longitude: number, zoom: number}) {
    return this.apiTokenPatch('api/orders/'+id+'/location', postData);
  }
  public deleteOrder(id: number) {
    return this.apiTokenDelete('api/orders/'+id);
  }

  public getOrderMessages(id: number) {
    return this.apiTokenGet('api/order-messages/'+id);
  }
  public createOrderMessage(postData: any) {
    return this.apiTokenPost('api/order-messages', postData);
  }
  public removeMessage(id: number, postData: any) {
    return this.apiTokenPatch('api/order-messages/'+id, postData);
  }

  public getOrderInstallerNotes(id: number) {
    return this.apiTokenGet('api/order-installer-notes/'+id);
  }
  public createOrderInstallerNote(postData: any) {
    return this.apiTokenPost('api/order-installer-notes', postData);
  }

  public getReports() {
    return this.apiTokenGet('api/reports');
  }
  public getReport(id: number) {
    return this.apiTokenGet('api/reports/'+id);
  }
  public createReport(postData: any) {
    return this.apiTokenPost('api/reports', postData);
  }
  public updateReport(id: number, postData: any) {
    return this.apiTokenPatch('api/reports/'+id, postData);
  }
  public deleteReport(id: number) {
    return this.apiTokenDelete('api/reports/'+id);
  }

  public getBrokers(sort?: TableColumnSort) {
    let query = this.serialize(sort);
    return this.apiTokenGet('api/brokers?'+query);
  }
  public getBrokersByCompany(id: number) {
    return this.apiTokenGet('api/brokers/company/'+id);
  }
  public getBrokersByOffice(id: number, sort?: SortableSearch) {
    let url = 'api/brokers/office/'+id;
    if(sort) {
      url += '?sort_property='+sort.sort_property+'&sort_direction='+sort.sort_direction;
    }
    return this.apiTokenGet(url);
  }
  public getBrokersSearch(search: BrokerSearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/brokers/search?'+query);
  }
  public getBrokerDetails(search: BrokerSearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/brokers/detailsearch?'+query);
  }
  public getBroker(id: number) {
    return this.apiTokenGet('api/brokers/'+id);
  }
  public createBroker(postData: any) {
    return this.apiTokenPost('api/brokers', postData);
  }
  public updateBroker(id: number, postData: any) {
    return this.apiTokenPatch('api/brokers/'+id, postData);
  }
  public deleteBroker(id: number) {
    return this.apiTokenDelete('api/brokers/'+id);
  }
  public exportBrokers(officeId: number) {
    return this.apiTokenGetBlob('api/brokers/export/'+officeId);
  }
  public importBrokers(officeId: number, fileUpload: File) {
    const formData = new FormData();
    formData.append("import_file", fileUpload);
    return this.apiTokenPost('api/brokers/import/'+officeId, formData);
  }

  public getRoles(search: RoleSearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/roles?'+query);
  }
  public getRole(id: number) {
    return this.apiTokenGet('api/roles/'+id);
  }
  public createRole(postData: any) {
    return this.apiTokenPost('api/roles', postData);
  }
  public updateRole(id: number, postData: any) {
    return this.apiTokenPatch('api/roles/'+id, postData);
  }
  public deleteRole(id: number) {
    return this.apiTokenDelete('api/roles/'+id);
  }
  public getRolesICanManage() {
    return this.apiTokenGet<Role[]>('api/roles/canManage');
  }

  public getPermissions() {
    return this.apiTokenGet('api/permissions');
  }
  public getPermission(id: number) {
    return this.apiTokenGet('api/permissions/'+id);
  }
  public createPermission(postData: any) {
    return this.apiTokenPost('api/permissions', postData);
  }
  public updatePermission(id: number, postData: any) {
    return this.apiTokenPatch('api/permissions/'+id, postData);
  }
  public deletePermission(id: number) {
    return this.apiTokenDelete('api/permissions/'+id);
  }

  public getCitySearch(search: CitySearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/cities/search?'+query);
  }
  public getCityDetails(search: CitySearch) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/cities/detailsearch?'+query);
  }

  public getMyCompanySubscriptionDetails() {
    return this.apiTokenGet('api/financial/mySubscription');
  }
  public isMyCompanySubscriptionActive() {
    return this.apiTokenGet('api/financial/mySubscriptionActive');
  }
  public myCompanyTrialStatus() {
    return this.apiTokenGet<SubscriptionTrialCheck>('api/financial/trialStatus');
  }
  public getMyCompanyInvoices() {
    return this.apiTokenGet('api/financial/myInvoices');
  }
  public getCompanyInvoices(id:number) {
    return this.apiTokenGet('api/financial/invoicesForCompany/'+id);
  }
  public getCompanySubscriptionDetails(id: number) {
    return this.apiTokenGet('api/financial/subscription/'+id);
  }
  public getStripeCheckoutForCompany() {
    return this.apiTokenGet('api/financial/checkoutSecret');
  }
  public getStripePortalUrl() {
    return this.apiTokenGet('api/financial/portalUrl');
  }
  public updateCompanySubscription(company_id: number, subscription_level: number) {
    return this.apiTokenPost('api/company/'+company_id+'/subscriptionLevel', {subscription_level: subscription_level});
  }
  public updateOfficeSubscription(office_id: number, subscription_level: number) {
    return this.apiTokenPost('api/office/'+office_id+'/subscriptionLevel', {subscription_level: subscription_level});
  }
  public getCompanySupportRequests(id:number) {
    return this.apiTokenGet('api/support-requests?company_id='+id);
  }
  public completeSupportRequest(id: number, postData: any) {
    return this.apiTokenPatch('api/support-requests/'+id, postData);
  }
  public supportRequest(postData: any) {
    return this.apiTokenPost('api/support-requests', postData);
  }
  public inviteUser(postData: any) {
    return this.apiTokenPost('api/invites', postData);
  }
  public touchInvite(id: number, postData: any) {
    return this.apiPublicPost('api/invites/'+id, postData);
  }
  public resendInvite(id: number, postData: any) {
    return this.apiTokenPost('api/invites/'+id+'/resend', postData);
  }
  public getInvites(search: any) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/invites?'+query);
  }
  public getCompanyInvites(id: number) {
    return this.apiTokenGet('api/company-invites/'+id);
  }
  public getMyInvites(search: any) {
    let query = this.serialize(search);
    return this.apiTokenGet('api/my-invites?'+query);
  }
  public getInvite(id: number) {
    return this.apiTokenGet('api/invites/'+id);
  }

  public prospectInvite(id: number) {
    return this.apiTokenGet('api/prospect-contacts/'+id+'/invite');
  }

  public login(postData){
    return this.apiPublicPost<LoginResult>('api/auth/login', postData);
  }
  public logout(){
    return this.apiTokenGet('api/auth/logout');
  }
  public resetPass(postData){
    return this.apiPublicPost('api/auth/forgot', postData);
  }
  public confirmResetPass(postData){
    return this.apiPublicPost('api/auth/confirmreset', postData);
  }

  public getNumbers(search){
    let query = this.serialize(search);
    return this.apiTokenGet('api/ui-numbers?'+query);
  }
  public getAdminNumbers(){
    return this.apiTokenGet('api/admin-ui-numbers');
  }
  public getAdminDashboardNumbers(search){
    let query = this.serialize(search);
    return this.apiTokenGet('api/admin-dashboard-numbers?'+query);
  }
  public getNotifications() {
    return this.apiTokenGet('api/notifications');
  }
  public getNotification(id: number) {
    return this.apiTokenGet('api/notifications/'+id);
  }
  public clearAllNotifications() {
    return this.apiTokenGet('api/notifications/clearAll');
  }

  public createOrUpdateZones(postData: any) {
    return this.apiTokenPost('api/zones', postData);
  }

  public clearAccessToken(){
    this.accessToken = null;
  }

  // private methods
  private serialize = function(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  private apiPublicGet<T>(apiMethod: string, params = null){
    return new Promise<T>((resolve, reject) => {
      this.http.get(this.url+apiMethod,{
        params: params
      }).subscribe((data: any) => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  private apiPublicPost<T>(apiMethod: string, postData){
    return new Promise<T>((resolve, reject) => {
      this.http.post(this.url+apiMethod, postData,{}).subscribe((data: any) => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  private apiPublicPatch<T>(apiMethod: string, postData){
    return new Promise<T>((resolve, reject) => {
      this.http.patch(this.url+apiMethod, postData,{}).subscribe((data: any) => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  private apiTokenGet<T>(apiMethod: string, params = null){
    return new Promise<T>((resolve, reject) => {
      let headers = null;
      this.auth.getAccessTokenPromise().then((data: any) => {
        this.accessToken = data;
        if (this.accessToken === null || this.accessToken === '') {
          throw new Error('Cannot make api call ' + apiMethod + '. No access.');
        } else {
          headers = {"Authorization": "Bearer "+ this.accessToken};
          this.http.get(this.url+apiMethod,{
            withCredentials: true,
            headers: headers,
            params: params
          }).subscribe((data: any) => {
            resolve(data);
          }, err => {
            reject(err);
          });
        }
      });
    });
  }

  private apiTokenGetBlob(apiMethod: string, params = null){
    return new Promise<HttpResponse<Blob>>((resolve, reject) => {
      let headers = null;
      this.auth.getAccessTokenPromise().then((data: any) => {
        this.accessToken = data;
        if (this.accessToken === null || this.accessToken === '') {
          throw new Error('Cannot make api call ' + apiMethod + '. No access.');
        } else {
          headers = {"Authorization": "Bearer "+ this.accessToken};
          this.http.get(this.url+apiMethod,{
            withCredentials: true,
            headers: headers,
            params: params,
            observe: 'response',
            responseType: 'blob'
          }).subscribe((data: any) => {
            resolve(data);
          }, err => {
            reject(err);
          });
        }
      });
    });
  }

  private apiTokenPost<T>(apiMethod, postData, params = null){
    return new Promise<T>((resolve, reject) => {
      let headers = null;
      this.auth.getAccessTokenPromise().then((data: any) => {
        this.accessToken = data;
        if (this.accessToken === null || this.accessToken === '') {
          throw new Error('Cannot make api call ' + apiMethod + '. No access.');
        } else {
          headers = {"Authorization": "Bearer "+ this.accessToken};
          this.http.post(this.url+apiMethod, postData, {
            withCredentials: true,
            headers: headers,
            params: params
          }).subscribe((data: any) => {
            resolve(data);
          }, err => {
            reject(err);
          });
        }
      });
    });
  }

  private apiTokenPatch<T>(apiMethod, patchData, params = null){
    return new Promise<T>((resolve, reject) => {
      let headers = null;
      this.auth.getAccessTokenPromise().then((data: any) => {
        this.accessToken = data;
        if (this.accessToken === null || this.accessToken === '') {
          throw new Error('Cannot make api call ' + apiMethod + '. No access.');
        } else {
          headers = {"Authorization": "Bearer "+ this.accessToken};
          this.http.patch(this.url+apiMethod, patchData, {
            withCredentials: true,
            headers: headers,
            params: params
          }).subscribe((data: any) => {
            resolve(data);
          }, err => {
            reject(err);
          });
        }
      });
    });
  }

  private apiTokenDelete<T>(apiMethod){
    return new Promise<T>((resolve, reject) => {
      let headers = null;
      this.auth.getAccessTokenPromise().then((data: any) => {
        this.accessToken = data;
        if (this.accessToken === null || this.accessToken === '') {
          throw new Error('Cannot make api call ' + apiMethod + '. No access.');
        } else {
          headers = {"Authorization": "Bearer "+ this.accessToken};
          this.http.delete(this.url+apiMethod, {
            withCredentials: true,
            headers: headers
          }).subscribe((data: any) => {
            resolve(data);
          }, err => {
            reject(err);
          });
        }
      });
    });
  }

  public getAndSetCsrfCookie(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.url+'sanctum/csrf-cookie').subscribe((data: any) => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

}
