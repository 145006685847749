import { Component } from '@angular/core';
import { ApiService } from '@app/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private apiService: ApiService
  ) {
    this.init();
  }

  private init (){
    this.apiService.getAndSetCsrfCookie();
  }
}
