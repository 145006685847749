import { Environment } from "@app/interfaces/environment.interface";

export const environment:Environment = {
  production: false,
  enforce_payments: false,
  api_url: 'https://dev.io.installmysigns.com/',
  environment_name: 'Dev',
  cache_key: 'ims_dev_',
  google_maps_api_key: 'AIzaSyD_vimQ_IgAC7eGSvj5_TaORbq4kGEULfg',
  stripe_key: 'pk_test_51NuyHECPBdMwDVorb5TXaPZ75wlnzy24qATGfP3HjJ3rTgd59r8zygCMROG9VK8Fan2AqJkiPMySPxlrUbDJ7joM00CgCvJByF',
};
