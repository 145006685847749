import { NgModule } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { QuillModule } from 'ngx-quill'

import { AuthService } from '@app/services/auth.service';
import { ApiService } from '@app/services/api.service';
import { DataService } from '@app/services/data.service';
import { TitleService } from '@app/services/title.service';
import { LocationService } from '@app/services/location.service';
import { GoogleMapsModule } from "@angular/google-maps";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
//const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    //ConfirmationDialogComponent,
  ],
  imports: [
    QuillModule.forRoot(),
    BrowserModule, 
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    GoogleMapsModule,
    TourMatMenuModule,
    //SocketIoModule.forRoot(config)
  ],
  providers: [
    AuthService,
    ApiService,
    DataService,
    TitleService,
    LocationService,
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
