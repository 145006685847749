import { Injectable } from '@angular/core';

interface SelectOption {
  value: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public removeItem(itemsArray, keyValueArray){
    for (const [key, entry] of Object.entries(keyValueArray)) {
      var removeIndex = itemsArray.map(function(item) { return item[entry['key']]; }).indexOf(entry['val']);
      itemsArray.splice(removeIndex, 1);
    }
    return itemsArray;
  }

  public keepItem(itemsArray, keyValueArray){
    var arrayOutput = [];
    for (const [key, entry] of Object.entries(keyValueArray)) {
      var keepIndex = itemsArray.map(function(item) { return item[entry['key']]; }).indexOf(entry['val']);
      arrayOutput.push(itemsArray[keepIndex]);
    }
    return arrayOutput;
  }

  public daysBetween(date1, date2 = null){
    date1 = new Date(date1);
    if(date2 == null){
      date2 = new Date();
    } else {
      date2 = new Date(date2);
    }
    const oneDay = 1000 * 60 * 60 * 24;
    const differenceMs = Math.abs(date1 - date2);
    let days = Math.round(differenceMs / oneDay);
    if(days > 60){
      return '60+d'
    } 
    return days+'d';
  }

  public months: SelectOption[] = 
  [
    {
      "name": "January",
      "value": "January"
    },
    {
      "name": "February",
      "value": "February"
    },
    {
      "name": "March",
      "value": "March"
    },
    {
      "name": "April",
      "value": "April"
    },
    {
      "name": "May",
      "value": "May"
    },
    {
      "name": "June",
      "value": "June"
    },
    {
      "name": "July",
      "value": "July"
    },
    {
      "name": "August",
      "value": "August"
    },
    {
      "name": "September",
      "value": "September"
    },
    {
      "name": "October",
      "value": "October"
    },
    {
      "name": "November",
      "value": "November"
    },
    {
      "name": "December",
      "value": "December"
    },
  ];
  
  public states: SelectOption[] = 
  [
    {
      "name": "Alabama",
      "value": "AL"
    },
    {
      "name": "Alaska",
      "value": "AK"
    },
    {
      "name": "American Samoa",
      "value": "AS"
    },
    {
      "name": "Arizona",
      "value": "AZ"
    },
    {
      "name": "Arkansas",
      "value": "AR"
    },
    {
      "name": "California",
      "value": "CA"
    },
    {
      "name": "Colorado",
      "value": "CO"
    },
    {
      "name": "Connecticut",
      "value": "CT"
    },
    {
      "name": "Delaware",
      "value": "DE"
    },
    {
      "name": "District Of Columbia",
      "value": "DC"
    },
    {
      "name": "Federated States Of Micronesia",
      "value": "FM"
    },
    {
      "name": "Florida",
      "value": "FL"
    },
    {
      "name": "Georgia",
      "value": "GA"
    },
    {
      "name": "Guam",
      "value": "GU"
    },
    {
      "name": "Hawaii",
      "value": "HI"
    },
    {
      "name": "Idaho",
      "value": "ID"
    },
    {
      "name": "Illinois",
      "value": "IL"
    },
    {
      "name": "Indiana",
      "value": "IN"
    },
    {
      "name": "Iowa",
      "value": "IA"
    },
    {
      "name": "Kansas",
      "value": "KS"
    },
    {
      "name": "Kentucky",
      "value": "KY"
    },
    {
      "name": "Louisiana",
      "value": "LA"
    },
    {
      "name": "Maine",
      "value": "ME"
    },
    {
      "name": "Marshall Islands",
      "value": "MH"
    },
    {
      "name": "Maryland",
      "value": "MD"
    },
    {
      "name": "Massachusetts",
      "value": "MA"
    },
    {
      "name": "Michigan",
      "value": "MI"
    },
    {
      "name": "Minnesota",
      "value": "MN"
    },
    {
      "name": "Mississippi",
      "value": "MS"
    },
    {
      "name": "Missouri",
      "value": "MO"
    },
    {
      "name": "Montana",
      "value": "MT"
    },
    {
      "name": "Nebraska",
      "value": "NE"
    },
    {
      "name": "Nevada",
      "value": "NV"
    },
    {
      "name": "New Hampshire",
      "value": "NH"
    },
    {
      "name": "New Jersey",
      "value": "NJ"
    },
    {
      "name": "New Mexico",
      "value": "NM"
    },
    {
      "name": "New York",
      "value": "NY"
    },
    {
      "name": "North Carolina",
      "value": "NC"
    },
    {
      "name": "North Dakota",
      "value": "ND"
    },
    {
      "name": "Northern Mariana Islands",
      "value": "MP"
    },
    {
      "name": "Ohio",
      "value": "OH"
    },
    {
      "name": "Oklahoma",
      "value": "OK"
    },
    {
      "name": "Oregon",
      "value": "OR"
    },
    {
      "name": "Palau",
      "value": "PW"
    },
    {
      "name": "Pennsylvania",
      "value": "PA"
    },
    {
      "name": "Puerto Rico",
      "value": "PR"
    },
    {
      "name": "Rhode Island",
      "value": "RI"
    },
    {
      "name": "South Carolina",
      "value": "SC"
    },
    {
      "name": "South Dakota",
      "value": "SD"
    },
    {
      "name": "Tennessee",
      "value": "TN"
    },
    {
      "name": "Texas",
      "value": "TX"
    },
    {
      "name": "Utah",
      "value": "UT"
    },
    {
      "name": "Vermont",
      "value": "VT"
    },
    {
      "name": "Virgin Islands",
      "value": "VI"
    },
    {
      "name": "Virginia",
      "value": "VA"
    },
    {
      "name": "Washington",
      "value": "WA"
    },
    {
      "name": "West Virginia",
      "value": "WV"
    },
    {
      "name": "Wisconsin",
      "value": "WI"
    },
    {
      "name": "Wyoming",
      "value": "WY"
    }
  ];

}
